<template>
    <OTabs class="mt-3" tabsClass="nav nav-tabs mb-2" tabContentClass="d-flex w-100 h-100" tabContentStyle="overflow-y:auto;" ref="tabs">
        <OTab :id="appliedFiltersTabID" paneClass="w-100 h-100" itemClass="flex-1" linkClass="w-100" :title="$t('Filters')" active>
            <OModal :title="modalFieldFilterItem?.isDate ? $t(`Date Presets (${modalFieldFilterItem?.title})`) : $t(`Distinct List (${modalFieldFilterItem?.title})`)"
                name="O365_filterItemDropdownModal"
                :alwaysRender="true"
                @hidden="filterObject.apply()">
                <div class="modal-body" v-if="modalFieldFilterItem">
                    <div class="w-100 h-100">
                        <Date v-if="modalFieldFilterItem.isDate" :filterObject="filterObject" :filterItem="modalFieldFilterItem"></Date>

                        <Distinct v-if="!modalFieldFilterItem.isDate" :filterObject="filterObject" :filterItem="modalFieldFilterItem" :key="modalFieldFilterItem.field"></Distinct>
                    </div>
                </div>
            </OModal>
            
            <div class="text-truncate ps-2 pb-2" style="min-height:30px;" v-if="filterObject.fieldFilters.length > 0 && filterObject.activeFilter">
                {{filterObject.activeFilter.FilterName}}
            </div>
            
            <div class="d-flex flex-1 flex-column p-1" style="overflow-y:auto;">
                <div v-if="filterObject.fieldFilters.length === 0" class="text-muted pt-3">
                    {{$t('No filter items defined')}}
                </div> 

                <div
                    class="mb-3"
                    :class="{ 'filter-list-bottom-spacing': index === filterObject.fieldFilters.length - 1 }"
                    v-for="(col, index) in filterObject.fieldFilters"
                    :key="index + col">
                    <FieldFilter
                        :filterObject="filterObject"
                        inputClassName="form-control form-control-sm rounded-0"
                        :columnName="col">
                    </FieldFilter>
                </div>

                <SaveFilter
                    className="position-absolute"
                    styleName="left:10px;bottom:10px;"
                    saveClass="btn btn-outline-primary bg-body"
                    saveAsClass="btn btn-outline-primary bg-body"
                    :dataObject="dataObject"
                    v-if="session.personId">
                </SaveFilter>

                <FieldFilterChooser :filterObject="dataObject.filterObject" />
            </div>
        </OTab>
        <OTab :id="savedFiltersTabID" itemClass="flex-1" linkClass="w-100" :title="$t('Saved Filters')" @click="navigateToAppliedFiller" v-if="session.personId">
            <div class="d-flex flex-column p-1">
                <FiltersList :filterObject="filterObject" :dataObject="dataObject"></FiltersList>
            </div>
        </OTab>
    </OTabs>
</template>

<script setup>
    import { defineProps, provide, ref } from "vue";
    import { getUserSession } from 'o365-modules';
    import SaveFilter from './components.SaveFilter.vue';
    import Date from './components.FilterEditor.Date.vue';
    import FieldFilter from './components.FieldFilter.vue';
    import FiltersList from './components.Filters.List.vue';
    import Distinct from './components.FilterEditor.Distinct.vue';
    import FieldFilterChooser from './components.FieldFilterChooser.vue';

    const props = defineProps(['dataObject', 'filterObject']);

    const tabs = ref(null);
    const appliedFiltersTabID = ref('appliedFiltersTab');
    const savedFiltersTabID = ref('savedFiltersTab');
    const modalFieldFilterItem = ref(null);

    const session = getUserSession();
    const filterObject = props.dataObject.filterObject??props.gridRef.dataObject.filterObject;

    const navigateToAppliedFiller = () => {
        tabs.value?.setActiveTab(appliedFiltersTabID.value);
    }

    const updateModalFieldFilterItem = (filterItem) => {
        modalFieldFilterItem.value = filterItem;//filterObject.getItem(columnName);
    }

    provide('O365_modalFieldFilterItem', { updateModalFieldFilterItem });
</script>
